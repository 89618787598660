import React, {useEffect, useRef, useState} from 'react';
import useUUID from "../../useUUID";

function InGameSettings(props) {

    const currentSettings = props?.currentSettings;

    const uuid = useUUID().uuid;

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    const thumbnails = importAll(require.context('../PuzzleBoard/img/thumbnails/', false, /\.(png|jpe?g|svg)$/));
    const bkgImages = importAll(require.context('../PuzzleBoard/img/', false, /\.(png|jpe?g|svg)$/));
    const solids = [
        "var(--umw-col-red)",
        "var(--umw-col-orange)",
        "var(--umw-col-yellow)",
        "var(--umw-col-blue)",
        "var(--umw-col-purple)"
    ];


    const [panelIdx, setPanelIdx] = useState(0);
    const [playerCount, setPlayerCount] = useState(currentSettings.playerCount);
    const [vowelCost, setVowelCost] = useState(currentSettings.vowelCost);
    const [activeBackdrop, setActiveBackdrop] = useState(currentSettings.backdrop);
    const [puzzleSet, setPuzzleSet] = useState(currentSettings.puzzleSet);

    const changeAllowed = useRef(false);
    const addPlayerApproved = useRef(false);
    const currentPuzzleDeleted = useRef(false);
    const nextPuzzleID = useRef(null);

    const updateCallback = props?.updateCallback;

    useEffect(() => {
        document.querySelector('.card.active').scrollIntoView({behavior: "instant", inline: "center"});
    }, []);


    // Checks to see if the given puzzle string will fit on the board (including spaces to avoid word-breaks)
    // puzzleString - The puzzle to check
    function puzzleCanFit(puzzleString) {
        let willFit = true;

        if (puzzleString.length <= 60) {

            let words = puzzleString.split(" ");
            let currentRowIdx = 1;
            let currentArrIdx = 0;

            for (const word of words) {
                const wordLen = word.length;

                if (wordLen > 15) {
                    willFit = false;
                    break;
                }

                if (currentArrIdx + wordLen <= 15 * currentRowIdx) {
                    currentArrIdx += wordLen
                } else {
                    currentArrIdx = currentRowIdx * 15;
                    currentRowIdx += 1
                    currentArrIdx += wordLen;
                }

                currentArrIdx += 1;

                if (currentArrIdx > 59) {
                    willFit = false;
                    break;
                }
            }

        } else {
            willFit = false;
        }

        return willFit;
    }

    function updateVowelCost(e) {
        let val = Number(e.target.value);
        if (!isNaN(val) && val >= 0 && val <= 99999) {
            setVowelCost(val);
        }
    }

    function getBackdrops() {
        let backdrops = [];
        for (const solid of solids) {
            backdrops.push(<div className={"card" + ((activeBackdrop === solid) ? " active" : "")}
                                key={solid}
                                style={{backgroundColor: solid}}
                                onClick={setBackdrop.bind(this, solid)}></div>)
        }
        for (const [key, path] of Object.entries(thumbnails)) {
            backdrops.push(<div className={"card" + ((activeBackdrop === key) ? " active" : "")}
                                key={key}
                                style={{backgroundImage: `url(${path})`}}
                                onClick={setBackdrop.bind(this, key)}></div>)
        }
        return backdrops;
    }

    function setBackdrop(img) {
        setActiveBackdrop(img);
    }

    function updatePuzzleSet(puzzleID, updateType, e) {

        if (!changeAllowed.current && puzzleID === currentSettings.puzzleID) {
            changeAllowed.current = window.confirm("If you change the current puzzle, you will need to restart the round. Are you sure you want to continue?");
            if (!changeAllowed.current) {
                e.preventDefault();
                return false;
            }
        }

        let puzzleUpdate = puzzleSet.slice();
        let puzzle = puzzleUpdate.find((x)=> x.id === puzzleID);
        if (puzzle) {

            if (updateType === "puzzle" && !puzzleCanFit(e.target.value)) {
                e.target.classList.add("invalid");
            } else {
                e.target.classList.remove("invalid");
            }

            puzzle[updateType] = e.target.value;
        }
        setPuzzleSet(puzzleUpdate);

    }

    function addNewPuzzleRow() {
        let puzzleUpdate = puzzleSet.slice();
        puzzleUpdate.push({
            id: uuid(),
            category: "",
            puzzle: "",
            finished: false
        });
        setPuzzleSet(puzzleUpdate);
    }

    function deletePuzzleRow(puzzleID, e) {
        if (puzzleID === currentSettings.puzzleID) {
            if (!window.confirm("Deleting the current puzzle will reset the current round and automatically load the next puzzle. Are you sure you want to delete this puzzle?")){
                e.preventDefault();
                return false;
            } else {
                currentPuzzleDeleted.current = true;
                let nextPuzzle = puzzleSet.findIndex((x)=> x.id === puzzleID) + 1;
                nextPuzzleID.current = (nextPuzzle > puzzleSet.length) ? null : puzzleSet[nextPuzzle].id;
            }
        }

        let puzzleUpdate = puzzleSet.slice();
        puzzleUpdate = puzzleUpdate.filter((x)=> x.id !== puzzleID);
        setPuzzleSet(puzzleUpdate);
    }

    function getPuzzleRows() {
        let puzzleRows = [];
        for (const puzzle of puzzleSet) {
            puzzleRows.push(
                <form key={puzzle.id} className="puzzle-row centered-flex gap-1">
                    <input required={true} type="text" maxLength="20" minLength="1" placeholder="Category" onChange={updatePuzzleSet.bind(this, puzzle.id, "category")} value={puzzle.category}/>
                    <input required={true} type="text" maxLength="60" minLength="1" className="flex-1" placeholder="Puzzle" onChange={updatePuzzleSet.bind(this, puzzle.id, "puzzle")} value={puzzle.puzzle}/>
                    <button className="delete-btn" onClick={deletePuzzleRow.bind(this, puzzle.id)}>×</button>
                </form>
            )
        }
        return puzzleRows;
    }

    function verifyPuzzleSet() {

        if (!puzzleSet || puzzleSet.length === 0) {
            alert("You must add at least one puzzle.");
            return false;
        }

        if (document.querySelectorAll('#game-setup .invalid').length > 0) {
            alert("There are some invalid puzzles in this set. If it is highlighted red, that means the puzzle will not physically fit on the board. Please edit it until it is no longer highlighted red.");
            return false;
        }

        let forms = document.querySelectorAll('#game-setup form');
        for (let i = 0; i < forms.length; i++) {
            if (!forms[i].reportValidity()) {
                alert("You are missing some required fields. Check your input and try again.");
                return false;
            }
        }

        return true;
    }

    function saveSettings() {

        if (updateCallback && verifyPuzzleSet()) {
            updateCallback({
                puzzleSet: puzzleSet.map(
                    (x) => {
                        x.category = x.category.toUpperCase();
                        x.puzzle = x.puzzle.toUpperCase();
                        return x;
                    }
                ),
                playerCount: playerCount,
                backdrop: activeBackdrop,
                vowelCost: vowelCost,
                restartRound: changeAllowed.current,
                currentPuzzleDeleted: currentPuzzleDeleted.current,
                nextPuzzleID: nextPuzzleID.current
            });
        }
    }

    function savePuzzleSet() {
        if (verifyPuzzleSet()) {
            let saveName = prompt("Please enter a name for this save file:");
            if (saveName) {
                let puzzleSetSlice = puzzleSet.slice();
                puzzleSetSlice = puzzleSetSlice.map((x)=> {
                    return {...x, finished: false}
                });
                downloadTxt(saveName, JSON.stringify(puzzleSetSlice));
            }
        }
    }

    function downloadTxt(saveName, content) {
        if ('Blob' in window) {
            if (saveName) {
                saveName += ".txt";
                let textToWrite = content.replace(/\n/g, "\r\n");
                let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });

                if ('msSaveOrOpenBlob' in navigator) {
                    navigator.msSaveOrOpenBlob(textFileAsBlob, saveName);
                } else {
                    let downloadLink = document.createElement('a');
                    downloadLink.download = saveName;
                    downloadLink.innerHTML = 'Download File';

                    if ('webkitURL' in window) {
                        // Chrome allows the link to be clicked without actually adding it to the DOM.
                        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
                    } else {
                        // Firefox requires the link to be added to the DOM before it can be clicked.
                        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
                        downloadLink.click(function(event){
                            document.body.removeChild(event.target);
                        });

                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                    }
                    downloadLink.click();
                }
            }
        } else {
            alert('Your browser does not support the HTML5 Blob.');
        }
    }

    let bkgPath = (activeBackdrop) ?
        ((bkgImages.hasOwnProperty(activeBackdrop)) ? bkgImages[activeBackdrop] :
            ((solids.includes(activeBackdrop) ? activeBackdrop : ""))) : "";

    return (
        <div id="game-setup" style={(solids.includes(activeBackdrop)) ? {backgroundColor: activeBackdrop} : {backgroundImage: `url(${bkgPath})`}}>
            {panelIdx === 0 && <div id="groupsPanel" className="panel">
                <header>Basic Settings:</header>
                <div className="centered-flex flex-col gap-1 flex-1">
                    <div className="settings-container-1">
                        <div>
                            <h1>How many players/groups?</h1>
                            <div id="player-count-entry" className="dec-inc-input">
                                <button className="dec-btn"
                                        onClick={(e)=> {
                                            if (playerCount - 1 < currentSettings.playerCount) {
                                                window.alert("You cannot decrease the current number of players.");
                                                return false;
                                            } else {
                                                setPlayerCount((playerCount > 1) ? playerCount - 1 : 1);
                                            }
                                        }}>−</button>
                                <div className="value">{playerCount}</div>
                                <button className="inc-btn"
                                        onClick={() => {
                                            if (!addPlayerApproved.current) {
                                                addPlayerApproved.current = window.confirm("If you add players, the current round's points will be reset. It's best to add players at the beginning of a new puzzle. Are you sure you want to continue?");
                                            }

                                            if(addPlayerApproved.current) {
                                                setPlayerCount((playerCount < 20) ? playerCount + 1 : 20);
                                            }

                                        }}>+</button>
                            </div>
                        </div>
                        <div>
                            <h1>How much does a vowel cost?</h1>
                            <div id="player-count-entry" className="dec-inc-input">
                                <button className="dec-btn"
                                        onClick={setVowelCost.bind(this, (vowelCost > 0) ? vowelCost - 1 : 0)}>−</button>
                                <input type="text" onChange={updateVowelCost} className="value" value={vowelCost}></input>
                                <button className="inc-btn"
                                        onClick={setVowelCost.bind(this, (vowelCost < 99999) ? vowelCost + 1 : 99999)}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="settings-container-2">
                        <h1 style={{margin:0}}>Choose a backdrop:</h1>
                        <div className="scrolling-wrapper">
                            {getBackdrops()}
                        </div>
                    </div>
                </div>
                <button style={{margin:"auto"}} onClick={setPanelIdx.bind(this, 1)}>Next</button>
            </div> }
            {panelIdx === 1 && <div id="createPanel" className="panel">
                <header>Puzzle Set</header>
                <div className="flex flex-col gap-1 flex-1">
                    <h1>Enter your puzzles below:</h1>
                    <button id="add-puzzle-btn" onClick={addNewPuzzleRow}>+</button>
                    <div id="puzzle-set-holder">
                        {getPuzzleRows()}
                    </div>
                    <div className="nav-holder">
                        <button onClick={setPanelIdx.bind(this,0)}>Back</button>
                        <button onClick={savePuzzleSet}>Download</button>
                        <button onClick={saveSettings}>Resume</button>
                    </div>
                </div>
            </div> }
        </div>
    );
}

export default InGameSettings;