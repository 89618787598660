import React, {} from 'react';
import WinnerList from "./WinnerList";
import Podium from "./Podium";
import { motion } from 'framer-motion'
import {ReactComponent as NoScore} from "../../static/img/noscore.svg";


function WinnerPodium(props) {

    const winners = props?.winners;

    const continueCallback = props?.continueCallback || (() => {})

    // TODO: Handle ties

    return (
        <>
            {winners?.length > 0 && <Podium winners={winners} /> }
            {winners?.length > 0 && <WinnerList winners={winners} /> }
            {(!winners || winners.length === 0) && <div id="no-winners" className="centered-flex flex-col flex-1">
                <NoScore height="100%"/>
                <div>No winners</div>
            </div>}
            <motion.button
                initial="hidden"
                animate="visible"
                variants={{
                    visible: () => ({
                        opacity: 1,
                        transition: {
                            delay: (!winners?.length > 0) ? 2 : 9,
                            duration: 2
                        }
                    }),
                    hidden: { opacity: 0 }
                }}
                id="podium-continue-btn"
                onClick={continueCallback}
            >Next</motion.button>
            {winners?.length > 0 && <div className="pyro">
                <div className="before"></div>
                <div className="after"></div>
            </div>}
        </>
    );
}

export default WinnerPodium;