import {useEffect, useMemo, useRef} from "react";

const useDebounce = (callback, delay) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return _debounce(func, delay);
    }, []);

    return debouncedCallback;
};

const _debounce = function debounce(cb, delay = 250) {
    let timeout

    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay)
    }
};


export default useDebounce;