import React, {useEffect, useState} from 'react';

import {ReactComponent as BankImg} from '../../static/img/bank.svg'
import {ReactComponent as WalletImg} from '../../static/img/wallet.svg';
import {ReactComponent as SaveImg} from "../../static/img/save.svg";
import AnimatedNumber from "../AnimatedNumber";

function GroupDisplay(props) {

    const groups = props?.groups;
    const currentGroup = props?.currentGroup;
    const groupClickHandler = props?.clickHandler || (()=>{});
    const updateGroupCallback = props?.updateGroupCallback;
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);

    useEffect(() => {
        if (currentGroup) {
            let el = document.querySelector(`[data-group="${currentGroup}"]`);
            if (el) {
                el.scrollIntoView({behavior: "instant", inline: "center"});
            }
        } else if (groups) {
            for (const group of groups) {
                if (!group.completedTurn) {
                    let el = document.querySelector(`[data-group="${group.id}"]`);
                    if (el) {
                        el.scrollIntoView({behavior: "instant", inline: "center"});
                    }
                    break;
                }
            }
        }
    }, [currentGroup]);


    function openGroupOptions(group, e) {
        e.stopPropagation();
        setEditingGroup(group);
        setShowEditGroup(true);
    }

    function updateGroup(prop, e) {
        let groupUpdate = {...editingGroup};
        if (prop && groupUpdate.hasOwnProperty(prop)) {
            let val = e.target.value;
            switch (prop) {
                case "name":
                    groupUpdate[prop] = val;
                    break;
                case "roundPoints":
                case "gamePoints":
                    val = Number(val);
                    let isValid = !isNaN(val);
                    if (isValid && val >= 0 && val <= 99999) {
                        groupUpdate[prop] = val;
                    }
                    break;
                default:
                    return;
            }
            setEditingGroup(groupUpdate);
        }
    }

    function finishEdit() {
        setShowEditGroup(false);
        updateGroupCallback(editingGroup.id, editingGroup);
        setEditingGroup(null);
    }

    function getGroups() {
        let groupElements = [];

        if (groups) {
            for (const group of groups) {
                let isEditing = (showEditGroup && editingGroup?.id === group.id);
                groupElements.push(<div
                    className={"group" +
                        ((group.completedTurn) ? " finished" : "") +
                        ((isEditing) ? " editing" : "")}
                    data-group={group.id}
                    key={`g-${group.id}`}
                    onClick={(showEditGroup) ? (()=>{}) : groupClickHandler.bind(this, group.id)}>
                    <button className="group-options"
                            onClick={(isEditing) ? finishEdit : openGroupOptions.bind(this, group)}>{(isEditing) ? <SaveImg height="100%"/> : "⋮"}</button>

                    {!isEditing && <span className="group-name">{group.name}</span>}
                    {isEditing && <input className="group-name" type="text" value={editingGroup.name} onChange={updateGroup.bind(this, "name")}/>}
                    <div className="points-display">
                        <span className="group-round-points"><i><WalletImg height="100%"/></i>
                            {!isEditing && <AnimatedNumber value={group.roundPoints}/>}
                            {isEditing && <input type="text"
                                                 onChange={updateGroup.bind(this, "roundPoints")}
                                                 value={editingGroup.roundPoints}/>}
                        </span>
                        <span className="group-game-points"><i><BankImg height="100%"/></i>
                            {!isEditing && <AnimatedNumber value={group.gamePoints}/>}
                            {isEditing && <input type="text"
                                                 onChange={updateGroup.bind(this, "gamePoints")}
                                                 value={editingGroup.gamePoints}/>}
                        </span>
                    </div>
                </div>);
            }
        }

        return groupElements;
    }

    return (
        <div id="group-display">
            {getGroups()}
        </div>
    );
}

export default GroupDisplay;