import React, {} from 'react';
import {ReactComponent as WalletImg} from "../../static/img/wallet.svg";
import AnimatedNumber from "../AnimatedNumber";
import {ReactComponent as BankImg} from "../../static/img/bank.svg";

function TurnOptionSelector(props) {

    const selectionCallback = props?.callback || (()=>{});
    const group = props?.group;
    const vowelCost = props?.vowelCost || 250;
    const vowelsPurchased = props?.vowelsPurchased;
    const consonantsUsed = props?.consonantsUsed;

    let gClass;

    if (group?.id) {
        let mod = (group.id % 4 === 0) ? 4 : group.id % 4;
        gClass = `g${mod}`;
    }

    let allowBuyVowel = (group?.roundPoints >= vowelCost && vowelsPurchased.length < 5);
    let allowSpin = (consonantsUsed.length < 21);

    return (
        <div id="turn-option-selector" className={gClass}>
            <button id="cancelBtn" className="game-btn" onClick={selectionCallback}>‹</button>
            <div id="button-holder">
                <div>
                    <button id="optionSpin" className={(!allowSpin) ? "disabled" : ""} onClick={selectionCallback.bind(null, 0)}>Spin</button>
                    <button id="optionSolve" onClick={selectionCallback.bind(null, 2)}>Solve</button>
                </div>
                <div>
                    <button id="optionVowel" className={(!allowBuyVowel) ? "disabled" : ""} onClick={selectionCallback.bind(null, 1)}>Buy vowel</button>
                    <button id="optionPass" onClick={selectionCallback.bind(null, 3)}>Pass</button>
                </div>
            </div>
            {group &&  <div id="currentGroupHUD">
                <div className={"group " + gClass}>
                    <span className="group-name">{group.name}</span>
                    <div className="points-display">
                        <span className="group-round-points"><i><WalletImg height="100%"/></i>
                            <AnimatedNumber value={group.roundPoints}/></span>
                        <span className="group-game-points"><i><BankImg height="100%"/></i>
                            <AnimatedNumber value={group.gamePoints}/></span>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default TurnOptionSelector;