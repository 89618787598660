import PodiumStep from './PodiumStep'

export default function Podium(props) {

    const winners = props?.winners || [];

    const podium = [8, 6, 4, 2, 0, 1, 3, 5, 7, 9]
        .reduce(
            (podiumOrder, position) => [...podiumOrder, winners[position]],
            [])
        .filter((x)=> x != null);

    return (
        <div id="podium">
            {podium.map((winner, index) => (
                <PodiumStep
                    key={winner.id}
                    podium={podium}
                    winner={winner}
                    index={index}
                />
            ))}
        </div>
    )
}