import './App.css';
import {useRef, useState, useEffect} from "react";
import PuzzleBoard from "./components/PuzzleBoard";
import GroupDisplay from "./components/GroupDisplay";
import RouletteWheel from "./components/RouletteWheel";
import LetterSelector from "./components/LetterSelector";
import Preloader from "./components/Preloader";


import playImg from './static/img/play.svg';
import bankImg from './static/img/bank.svg';
import walletImg from './static/img/wallet.svg';
import noWinnerImg from './static/img/noscore.svg';
import {ReactComponent as HelpImg} from "./static/img/help.svg";
import {ReactComponent as StopImg} from "./static/img/stop.svg";
import {ReactComponent as SettingsImg} from "./static/img/settings.svg";
import {ReactComponent as SkipImg} from "./static/img/skip.svg";

import bankruptAud from './static/audio/bankrupt.mp3';
import correctAud from './static/audio/correct.mp3';
import incorrectAud from './static/audio/incorrect.mp3';
import newPuzzleAud from './static/audio/new_puzzle.mp3';
import selectionAud from './static/audio/selection.mp3';
import solvedAud from './static/audio/solved.mp3';
import cashAud from './static/audio/cash.mp3';
import loseATurnAud from './static/audio/loseaturn.mp3';
import bankAud from './static/audio/bank.mp3';
import cheerAud from './static/audio/cheering.mp3';
import payingAud from './static/audio/pay.mp3';

import TurnOptionSelector from "./components/TurnOptionSelector";
import SolveThePuzzle from "./components/SolveThePuzzle";
import GameSetup from "./components/GameSetup";
import InGameSettings from "./components/InGameSettings";
import useDebounce from "./useDebounce";
import WinnerPodium from "./components/WinnerPodium";
import HelpScreen from "./components/HelpScreen";

// TODO: Fix add puzzle button on iPad
// TODO: Maybe try to fix up the visuals???
// TODO: Handle ties in Podium
// TODO: Implement bonus items (acquiring, holding, using)
    // TODO: Tie-breaker Belt (any team with this belt will always come out on top if there is a tie) - 1 per game
    // TODO: Point Leech (any team with this item will receive a % of the top-scoring team's points at the end of the round) - 1 per round, disappears at the end of each round.
    // TODO: Poison (a team can use this on another team. The team that it is used on will lose points each turn (round points) until the next puzzle.
    // TODO: Ring of Power (any team with this ring will receive a % boost to the points they receive during the round) - 1 per round, disappears at the end of the round.

function App() {

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    const backdrops = importAll(require.context('./components/PuzzleBoard/img/', false, /\.(png|jpe?g|svg)$/));
    const solids = [
        "var(--umw-col-green)",
        "var(--umw-col-red)",
        "var(--umw-col-orange)",
        "var(--umw-col-yellow)",
        "var(--umw-col-blue)",
        "var(--umw-col-purple)"
    ];

    const SOLVE_POINTS = 3000;

    const [puzzle, setPuzzle] = useState(null);

    const [showLetterSelector, setShowLetterSelector] = useState(false);
    const [showRouletteWheel, setShowRouletteWheel] = useState(false);
    const [showTurnOptions, setShowTurnOptions] = useState(false);
    const [showSolveThePuzzle, setShowSolveThePuzzle] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [puzzleSolved, setPuzzleSolved] = useState(false);
    const [showPodium, setShowPodium] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [setUp, setSetUp] = useState(false);
    const [backdrop, setBackdrop] = useState();

    const [boardState, setBoardState] = useState(initBoardState());
    const [groups, setGroups] = useState([]);

    const playingGroupID = useRef(null);
    const buyingAVowel = useRef(false);
    const freeVowel = useRef(false);
    const letterValue = useRef(0);
    const guessesToClear = useRef(0);
    const vowelCost = useRef(250);
    const vowelsPurchased = useRef([]);
    const consonantsUsed = useRef([]);
    const puzzleSet = useRef([]);
    const skipped = useRef(false);
    const backdropRawName = useRef(null);

    const audioContainer = useRef(null);

    useEffect(()=> {

        if (loaded && setUp) {
            let cleanString = puzzle.puzzle
                .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g,"")
                .replace(/\s{2,}/g," ");
            getBoard(cleanString);
            if (audioContainer.current) {
                audioContainer.current.play().catch((e)=>{alert(`NOPE: ${e}`)});
            }
        }

    }, [puzzle, puzzle?.puzzle, loaded, setUp]);

    useEffect(()=> {
        let resetGroups = (groups?.length > 0);
        for (const group of groups) {
            if (!group.completedTurn) {
                resetGroups = false
                break;
            }
        }

        if (resetGroups) {
            let groupsUpdate = groups.slice();
            for (const group of groupsUpdate) {
                group.completedTurn = false;
            }
            setGroups(groupsUpdate);
        }

    }, [groups]);

    function initBoardState() {
        let initState = [];
        for (let i = 0; i < 60; i++) {
            initState.push({used: false, value: "", guessed: false, show: false});
        }
        return initState;
    }

    function backdropSelected(name) {
        backdropRawName.current = name;
        if (name && backdrops.hasOwnProperty(name)) {
            setBackdrop(backdrops[name]);
        } else if (name && solids.includes(name)) {
            setBackdrop(name);
        }
    }

    function getBoard(puzzleString) {
        let words = puzzleString.split(" ");
        let currentRowIdx = 1;
        let currentArrIdx = 0;
        let newBoard = boardState.slice();

        function addWord(word) {
            for (let i = 0; i < word.length; i++) {
                newBoard[currentArrIdx] = {used: true, value: word[i].toUpperCase(), guessed: false};
                currentArrIdx++;
            }
        }

        for (const word of words) {
            const wordLen = word.length;
            if (currentArrIdx + wordLen <= 15 * currentRowIdx) {
                addWord(word);
            } else {
                currentArrIdx = currentRowIdx * 15;
                currentRowIdx += 1
                if (currentArrIdx + wordLen < 60) {
                    addWord(word);
                } else {
                    // The puzzle is too long, so truncate the rest.
                    break;
                }
            }

            newBoard[currentArrIdx] = {used: false, value: "", guessed: false};
            currentArrIdx += 1;
        }
        setBoardState(newBoard);
    }

    function afterRoulette (points) {
        let isNumber = !isNaN(Number(points));

        if (isNumber) {
            letterValue.current = Number(points);
            setShowLetterSelector(true);
        } else {
            switch (points) {
                case "BANKRUPT":
                    window.UNMEIWA.sounds[bankruptAud].play();
                    setTimeout(()=>{
                        updateGroup(playingGroupID.current, {completedTurn: true, roundPoints: 0})
                        playingGroupID.current = null;
                    }, 500);
                    break;
                case "FREE VOWEL":
                    buyingAVowel.current = true;
                    freeVowel.current = true;
                    setShowLetterSelector(true);
                    break;
                case "LOSE A TURN":
                    window.UNMEIWA.sounds[loseATurnAud].play();
                    updateGroup(playingGroupID.current, {completedTurn: true});
                    playingGroupID.current = null;
                    break;
                default:
                    break;
            }
        }
        setShowRouletteWheel(false);
    }

    function afterLetterSelection (letter) {

        // Check if letter in puzzle
        // Set puzzle board square states (pass as prop)
        // Multiply points by number of squares correct
        // Add points to group current points account

        freeVowel.current = false;
        const isVowel = "AEIOU".indexOf(letter) !== -1;
        if (isVowel && !vowelsPurchased?.current.includes(letter)) {
            vowelsPurchased.current.push(letter);
        }

        if (!isVowel) {
            consonantsUsed.current.push(letter);
        }

        if (puzzle.puzzle.indexOf(letter) === -1) {
            let endTurn = ()=> {
                setShowLetterSelector(false);
                updateGroup(playingGroupID.current, {completedTurn: true});
                playingGroupID.current = null;
                buyingAVowel.current = false;
            };

            let aud = window.UNMEIWA.sounds[incorrectAud];
            aud.onended = endTurn;
            aud.play().catch(endTurn);
        } else {
            setShowLetterSelector(false);
            window.UNMEIWA.sounds[correctAud].play();
            let boardStateUpdate = boardState.slice();
            for (let block of boardStateUpdate) {
                if (block.value === letter) {
                    block.guessed = true;
                    guessesToClear.current += 1;
                }
            }
            setBoardState(boardStateUpdate);
        }


    }

    function handleGuessedClick(blockIdx, e) {
        let boardStateUpdate = boardState.slice();
        boardStateUpdate[blockIdx].guessed = false;

        if (!buyingAVowel.current) {
            let cashSound = window.UNMEIWA.sounds[cashAud];
            if (cashSound.currentTime !== 0 && !cashSound.ended) {
                cashSound.currentTime = 0;
            }  else {
                cashSound.play();
            }
            updateGroup(playingGroupID.current, {
                roundPoints: getGroupByID(playingGroupID.current).roundPoints + letterValue.current
            });
        }

        boardStateUpdate[blockIdx].show = true;
        setBoardState(boardStateUpdate);

        guessesToClear.current -= 1;

        if (guessesToClear.current === 0) {

            let emptySquare = boardStateUpdate.find((x)=> x.used && !x.show);
            if (!emptySquare) {
                window.UNMEIWA.sounds[solvedAud].play();
                setShowSolveThePuzzle(true);
            } else {
                buyingAVowel.current = false;
                setTimeout(setShowTurnOptions.bind(this, true), 1800);
            }
        }
    }

    function getGroupByID(groupID) {
        let filtered = groups.filter((v)=> v.id === groupID);
        return (filtered.length > 0) ? filtered[0] : null;
    }

    function updateGroup(groupID, props) {
        let groupUpdate = groups.slice();
        for (let i = 0; i < groupUpdate.length; i++) {
            let group = groupUpdate[i];
            if (group.id === groupID) {
                groupUpdate[i] = {...group, ...props};
                break;
            }
        }
        setGroups(groupUpdate);
    }

    function handleGroupClick(groupID) {
        if (!playingGroupID.current && !puzzleSolved) {
            let group = getGroupByID(groupID);
            if (group && !group.completedTurn) {
                playingGroupID.current = group.id;
                setShowTurnOptions(true);
            }
        }
    }

    function doTurnOption(option) {

       switch (option) {
           // Spin
           case 0:
               setShowTurnOptions(false);
               setShowRouletteWheel(true);
               break;
           // Buy a vowel
           case 1:
               buyingAVowel.current = true;
               updateGroup(playingGroupID.current,
                   {roundPoints: getGroupByID(playingGroupID.current).roundPoints - vowelCost.current});
               window.UNMEIWA.sounds[payingAud].play();
               setTimeout(()=> {
                   setShowTurnOptions(false);
                   setShowLetterSelector(true);
               }, 2000);
               break;
           // Solve the puzzle
           case 2:
               setShowTurnOptions(false);
               setShowSolveThePuzzle(true);
               break;
           // Pass turn
           case 3:
               setShowTurnOptions(false);
               updateGroup(playingGroupID.current, {completedTurn: true});
               playingGroupID.current = null;
               break;
           default:
               setShowTurnOptions(false);
               playingGroupID.current = null;
               break;
       }

    }

    function correctSolve() {
        setShowSolveThePuzzle(false);
        puzzleSet.current.find((x)=> x.id === puzzle.id).finished = true;
        setPuzzleSolved(true);
        setTimeout(()=> {
            window.UNMEIWA.sounds[cashAud].play();
            updateGroup(playingGroupID.current, {
                roundPoints: getGroupByID(playingGroupID.current).roundPoints + SOLVE_POINTS
            });
            playingGroupID.current = null;
        }, 500);
    }

    function incorrectSolve() {

        function finish() {
            setShowSolveThePuzzle(false);
            updateGroup(playingGroupID.current, {completedTurn: true});
            playingGroupID.current = null;
        }

        let aud = window.UNMEIWA.sounds[incorrectAud]
        aud.onended = finish;
        aud.play().catch(finish);
    }

    function finishPuzzle() {

        function finish() {
            setTimeout(nextPuzzle, 1800);
        }

        if (!skipped.current) {
            let groupsUpdate = groups.slice();
            let hasRoundPoints = groupsUpdate.find((x)=> x.roundPoints > 0);

            if (hasRoundPoints) {
                groupsUpdate.forEach((x)=> {
                    x.gamePoints += x.roundPoints;
                    x.roundPoints = 0;
                    x.completedTurn = false;
                });

                let aud = window.UNMEIWA.sounds[bankAud];
                aud.onended = finish;
                aud.play().catch(finish);
                setGroups(groupsUpdate);
            } else {
                groupsUpdate.forEach((x)=> {
                    x.completedTurn = false;
                });
                setGroups(groupsUpdate);
                finish();
            }

        } else {
            skipped.current = false;
            let groupsUpdate = groups.slice();
            groupsUpdate.forEach((x)=> {
                x.roundPoints = 0;
                x.completedTurn = false;
            });
            setGroups(groupsUpdate);
            finish();
        }

    }

    function reset() {
        playingGroupID.current = null;
        buyingAVowel.current = false;
        freeVowel.current = false;
        letterValue.current = 0;
        guessesToClear.current = 0;
        vowelsPurchased.current = [];
        consonantsUsed.current = [];
        setPuzzleSolved(false);
    }

    function hasNextPuzzle() {
        return puzzleSet.current?.find((x) => !x.finished);
    }

    function nextPuzzle() {
        let next = hasNextPuzzle();
        if (next) {
            reset();
            setBoardState(initBoardState());
            setPuzzle(next);
        } else {
            gameOver();
        }
    }

    function finalizeSetup(puzzles, groupCount, vowelPrice) {

        if (puzzles) {
            puzzleSet.current = puzzles;
            let groupsUpdate = [];

            for (let i = 0; i < groupCount; i++) {
                groupsUpdate.push(
                    {id: i+1, name:`Group ${i+1}`, roundPoints: 0, gamePoints: 0, completedTurn: false},
                )
            }

            audioContainer.current.autoplay = false;
            audioContainer.current.src = newPuzzleAud;
            audioContainer.current.load();

            vowelCost.current = vowelPrice;
            setGroups(groupsUpdate);
            nextPuzzle();
            setSetUp(true);
        }
    }

    function updateSettings(settingsObj) {

        if (settingsObj.restartRound) {
            let currentPuzzleID = puzzle.id;
            let matchingPuzzle = settingsObj.puzzleSet.find((x)=> x.id === currentPuzzleID);
            if (matchingPuzzle) {
                puzzleSet.current = settingsObj.puzzleSet;
                reset();
                setPuzzle(matchingPuzzle);
            }
        } else if (settingsObj.currentPuzzleDeleted) {
            if (settingsObj.nextPuzzleID) {
                let matchingPuzzle = settingsObj.puzzleSet.find((x)=> x.id === settingsObj.nextPuzzleID);
                if (!matchingPuzzle) {
                    let nextPuzzle = settingsObj.puzzleSet.findIndex((x)=> !x.finished);
                    matchingPuzzle = (nextPuzzle !== -1) ? settingsObj.puzzleSet[nextPuzzle] : null;
                }

                if (!matchingPuzzle) {
                    gameOver();
                } else {
                    puzzleSet.current = settingsObj.puzzleSet;
                    reset();
                    setPuzzle(matchingPuzzle);
                }
            } else {
                gameOver();
            }
        } else {
            puzzleSet.current = settingsObj.puzzleSet;
        }

        vowelCost.current = settingsObj.vowelCost;

        if (settingsObj.playerCount > groups.length || settingsObj.restartRound) {
            let groupsUpdate = groups.slice();
            for (let i = groups.length; i < settingsObj.playerCount; i++) {
                groupsUpdate.push(
                    {id: i+1, name:`Group ${i+1}`, roundPoints: 0, gamePoints: 0, completedTurn: false},
                )
            }
            for (let i = 0; i < groupsUpdate.length; i++) {
                groupsUpdate[i].roundPoints = 0;
                groupsUpdate[i].completedTurn = false;
            }
            setGroups(groupsUpdate);
        }

        if (settingsObj.backdrop !== backdropRawName.current) {
            backdropSelected(settingsObj.backdrop);
        }

        setShowSettings(false);

    }

    function gameOver() {

        function finish() {
            setShowPodium(true);
            if (getWinnerData()) {
                window.UNMEIWA.sounds[solvedAud].play();
                window.UNMEIWA.sounds[cheerAud].play();
            } else {
                window.UNMEIWA.sounds[loseATurnAud].play();
            }

        }

        let groupsUpdate = groups.slice();
        let hasRoundPoints = groupsUpdate.find((x)=> x.roundPoints > 0);

        if (hasRoundPoints && !skipped.current) {
            groupsUpdate.forEach((x)=> {
                x.gamePoints += x.roundPoints;
                x.roundPoints = 0;
                x.completedTurn = false;
            });

            let aud = window.UNMEIWA.sounds[bankAud];
            aud.onended = finish;
            aud.play().catch(finish);
            setGroups(groupsUpdate);
        } else {
            finish();
        }
    }

    function podiumContinueHandler() {
        window.location.reload();
    }

    function getWinnerData() {
        let winnerData = [];
        let groupSlice = groups.slice();
        let hasPoints = groupSlice.find((x)=> x.gamePoints > 0);
        if (hasPoints) {
            groupSlice.sort((a,b) => b.gamePoints - a.gamePoints);
            const max = Math.min(groupSlice.length, 3);

            for (let i = 0; i < max; i++) {
                let group = groupSlice[i];
                winnerData.push({
                    id: group.id,
                    name: group.name,
                    rank: i+1,
                    score: group.gamePoints
                });
            }

            return winnerData.sort((a, b) => a.rank - b.rank).map((winner, place) => ({ ...winner, place }));
        } else {
            return null;
        }
    }



    function getCurrentSettings() {
        return {
            puzzleSet: puzzleSet.current,
            vowelCost: vowelCost.current,
            playerCount: groups.length,
            puzzleID: puzzle.id,
            backdrop: backdropRawName.current
        }
    }

    let currentGroup = getGroupByID(playingGroupID.current);
    let dialogOpen = showRouletteWheel || showLetterSelector || showSolveThePuzzle || showPodium;
    let fullyLoaded = loaded && setUp;

    const skipButton = useDebounce(()=>{
        if (!puzzleSolved) {
            skipped.current = true;
            puzzleSet.current.find((x)=> x.id === puzzle.id).finished = true;
            setPuzzleSolved(true);
        }
    });

    return (
        <div id="app-container">
            {fullyLoaded && showRouletteWheel && <RouletteWheel backdrop={backdrop} group={currentGroup} boardState={boardState} puzzle={puzzle} callback={afterRoulette}/>}
            {fullyLoaded && <LetterSelector isFreeVowel={freeVowel.current} backdrop={backdrop} show={showLetterSelector} vowelsEnabled={buyingAVowel.current} boardState={boardState} puzzle={puzzle} callback={afterLetterSelection}/>}
            {fullyLoaded && showSolveThePuzzle && <SolveThePuzzle backdrop={backdrop} boardState={boardState} puzzle={puzzle} correctCallback={correctSolve} incorrectCallback={incorrectSolve}/>}
            {fullyLoaded && !dialogOpen && (<>
                <PuzzleBoard showNextBtn={(puzzleSolved && hasNextPuzzle())} showFinishBtn={!hasNextPuzzle()} backdrop={backdrop} nextCallback={finishPuzzle} solved={puzzleSolved} puzzle={puzzle} boardState={boardState} guessedCallback={handleGuessedClick}/>
                {!showTurnOptions &&
                    <div id="lower-section" className="flex flex-nowrap">
                        <div className="flex flex-col gap-1 justify-between">
                            <button className="game-btn" onClick={setShowSettings.bind(this, true)}><SettingsImg height="100%"/></button>
                            <button className="game-btn" onClick={skipButton}><SkipImg height="100%"/></button>
                            <button className="game-btn" onClick={gameOver}><StopImg height="100%"/></button>
                            <button className="game-btn" onClick={setShowHelp.bind(this, true)}><HelpImg height="100%"/></button>
                        </div>
                        <GroupDisplay updateGroupCallback={updateGroup} currentGroup={playingGroupID.current} groups={groups} clickHandler={handleGroupClick}/>
                    </div>}
                {showTurnOptions && <TurnOptionSelector consonantsUsed={consonantsUsed.current} vowelsPurchased={vowelsPurchased.current} vowelCost={vowelCost.current} group={currentGroup} callback={doTurnOption}/>}
            </>)}
            {!loaded && <Preloader callback={()=> {
                audioContainer.current = new Audio();
                audioContainer.autoplay = true;
                audioContainer.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
                setLoaded(true);
            }}
                                   images={[playImg, bankImg, walletImg, noWinnerImg, ...Object.values(backdrops)]}
                                   audio={[payingAud, bankruptAud, correctAud, incorrectAud, newPuzzleAud, selectionAud, solvedAud, cashAud, loseATurnAud, bankAud, cheerAud]} />}
            {loaded && !setUp && <GameSetup setupCallback={finalizeSetup} backdropCallback={backdropSelected}/>}
            {fullyLoaded && showSettings && <InGameSettings currentSettings={getCurrentSettings()} updateCallback={updateSettings}/>}
            {fullyLoaded && showPodium && <WinnerPodium continueCallback={podiumContinueHandler} winners={getWinnerData()}/>}
            {fullyLoaded && showHelp && <HelpScreen closeCallback={setShowHelp.bind(this, false)}/>}
        </div>
    );
}

export default App;
