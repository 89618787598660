import React, {useEffect, useState} from 'react';
import PuzzleBoard from "../PuzzleBoard";

function LetterSelector(props) {

    const puzzle = props?.puzzle || "";
    const boardState = props?.boardState;
    const vowelsEnabled = props?.vowelsEnabled || false;
    const backdrop = props?.backdrop;
    const show = props?.show !== false;
    const isFreeVowel = props?.isFreeVowel || false;

    const selectionCallback = props?.callback || (()=>{});

    const [selectionState, setSelectionState] = useState(getInitSelectionState());

    function getInitSelectionState() {
        let initState = [];
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const vowels = "AEIOU";
        const puzzleString = puzzle.puzzle.toUpperCase();
        for (let i = 0; i < 26; i++) {
            initState.push({
                is_correct: (puzzleString.indexOf(alphabet[i]) !== -1),
                used: false,
                value: alphabet[i],
                is_vowel: vowels.indexOf(alphabet[i]) !== -1
            });
        }
        return initState;
    }

    useEffect(()=> {
        setSelectionState(getInitSelectionState());
    }, [puzzle, puzzle?.puzzle]);

    function selectionHandler(idx, e) {
        let newState = selectionState.slice();
        newState[idx].used = true;
        setSelectionState(newState);
        selectionCallback(newState[idx].value);
    }

    function getBlocks() {

        let blocks = [];

        if (selectionState) {
            for (let i = 0; i < selectionState.length; i++) {
                let block = selectionState[i];
                let className = `a${i+1}` +
                    ((block.used) ? " used" : "") +
                    ((block.is_correct) ? " correct" : " ng") +
                    ((block.is_vowel) ? " vowel" : "") +
                    (((block.is_vowel && !vowelsEnabled) || (!block.is_vowel && vowelsEnabled)) ? " disabled" : "");

                blocks.push(<div key={block.value} className={className} onClick={selectionHandler.bind(null, i)}>{block.value}</div>)
            }
        }
        return blocks;
    }

    return (
        <>
            {show && <div id="letter-selector" className={(isFreeVowel) ? "is-free" : ""}>
                {getBlocks()}
                <div className="puzzle">
                    <PuzzleBoard backdrop={backdrop} puzzle={puzzle} boardState={boardState}/>
                </div>
            </div> }
        </>
    );
}

export default LetterSelector;