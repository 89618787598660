import React, {} from 'react';

import {ReactComponent as BankImg} from '../../static/img/bank.svg';
import {ReactComponent as WalletImg} from '../../static/img/wallet.svg';
import {ReactComponent as StopImg} from "../../static/img/stop.svg";
import {ReactComponent as SettingsImg} from "../../static/img/settings.svg";
import {ReactComponent as SkipImg} from "../../static/img/skip.svg";
import {ReactComponent as SaveImg} from "../../static/img/save.svg";

function HelpScreen(props) {

    const closeCallback = props?.closeCallback || (()=>{});

    return (
        <>
            <div id="help-screen" className="modal fade show" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header hstack gap-2">
                            <h1 className="modal-title fs-2 flex-1" id="helpModalLabel">Help</h1>
                            <button type="button" className="btn-close" onClick={closeCallback} aria-label="Close">×</button>
                        </div>
                        <div className="modal-body">
                            <h3>Game Information</h3>
                            <p style={{textIndent: "1rem"}}>
                                This game is a Wheel of Fortune clone. First, select a team to start their turn. Next, the team will have four options:
                            </p>
                            <ul>
                                <li><b>Spin</b> - Spin the roulette wheel. If the wheels lands on a numerical value, the students can guess a consonant. They will receive that number of points for each time the consonant appears in the puzzle. This button will be disabled if students have selected all possible consonants.</li>
                                <li><b>Buy a vowel</b> - After students have scored some points for the round, they can choose to buy a vowel. By default, vowels cost 250 points (this can be changed). They will not receive points if the vowel is in the puzzle. This button will be disabled if students don't have enough money in their wallet to buy a vowel or if all vowels have already been purchased.</li>
                                <li><b>Solve</b> - If they know the answer, they can try to solve the puzzle. If they solve the puzzle correctly, that team will win 3000 points.</li>
                                <li><b>Pass</b> - End the group's turn (the teacher can choose to not allow students to use this option)</li>
                            </ul>
                            <h3>Points</h3>
                            <p style={{textIndent: "1rem"}}></p>
                            <ul className="no-bullets">
                                <li><i className="icon"><WalletImg height="100%"/></i> During the round, any points that the teams receive will go into their team's wallet. The points in their wallet is their score for the current round. These points can be used to buy vowels and the points they will lose if they land on "bankrupt".</li>
                                <br/>
                                <li><i className="icon"><BankImg height="100%"/></i> Once the puzzle is solved, the points in their wallet will be transferred to their bank. The points in their bank is their score for the overall game. These points are <b>not</b> affected by landing on "bankrupt". These points are used to determine the winner of the game.</li>
                            </ul>
                            <h3>Buttons:</h3>
                            <ul className="buttons no-bullets">
                                <li><i className="icon"><SettingsImg height="100%"/></i> Opens the settings menu. Please see "Settings" below for more information.
                                </li>
                                <li><i className="icon"><SkipImg height="100%"/></i> Skips the current puzzle. <b>If you skip a puzzle, students will not receive any points for that round!</b>
                                </li>
                                <li><i className="icon"><StopImg height="100%"/></i> Ends the game. Once you click this, the game will end,
                                    and the winner
                                    podium will display.
                                    Scores will be reset to zero.
                                </li>
                                <li><i className="icon" style={{height:"1rem"}}><b style={{lineHeight:"0.5rem", fontSize:"3.5rem", fontWeight:"bold", fontStyle:"normal"}}>‹</b></i> Use this button to leave the turn options screen if you accidentally click the wrong group.
                                </li>
                                <li><i className="icon" style={{textAlign:"center"}}><b style={{lineHeight:"1.2rem", fontSize:"2.5rem", fontWeight:"bold", fontStyle:"normal"}}>⋮</b></i> This button allows you to change the group's name and/or manually change the group's scores.</li>
                                <li><i className="icon"><SaveImg height="100%"/></i> Saves the changes made to the group's name and/or scores.</li>
                            </ul>
                            <h3>Settings:</h3>
                            <ul>
                                <li><b>Number of players:</b> The number of players or teams that are currently playing.
                                    After you start the game you can only increase this number. If you increase the number
                                    of players after the game has already started, the current round will be reset.
                                </li>
                                <li><b>Vowel cost:</b> This option allows you to get the cost of buying a vowel.
                                    The default value is 250 points.
                                </li>
                                <li><b>Backdrop:</b> You can choose various backdrops for the puzzle board area.
                                    I have included some various holiday backdrops for holiday-themed lessons.
                                </li>
                                <li><b>Puzzle Set:</b> On this screen, you can add/delete/edit the puzzles in the current game.
                                    <b>If you edit the current puzzle, it will reset the current round. If you delete the current puzzle,
                                        the next puzzle will automatically be loaded, and a new round will start.</b><br/><br/>
                                    Using the "Download" button, you can save the current puzzle set for later use. Once it is saved, you can
                                    load it during game setup.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop"></div>
        </>
    );
}

export default HelpScreen;