import React, {useEffect, useState} from 'react';
import useUUID from "../../useUUID";
import useDebounce from "../../useDebounce";

function GameSetup(props) {

    // Vowel cost
    // Choose backdrop
    // Add/remove/edit puzzles
    // Save/load puzzle set

    const uuid = useUUID().uuid;

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    const thumbnails = importAll(require.context('../PuzzleBoard/img/thumbnails/', false, /\.(png|jpe?g|svg)$/));
    const bkgImages = importAll(require.context('../PuzzleBoard/img/', false, /\.(png|jpe?g|svg)$/));
    const solids = [
        "var(--umw-col-red)",
        "var(--umw-col-orange)",
        "var(--umw-col-yellow)",
        "var(--umw-col-blue)",
        "var(--umw-col-purple)"
    ];


    const [panelIdx, setPanelIdx] = useState(0);
    const [playerCount, setPlayerCount] = useState(1);
    const [vowelCost, setVowelCost] = useState(250);
    const [activeBackdrop, setActiveBackdrop] = useState(null);
    const [puzzleSet, setPuzzleSet] = useState([{
            id: uuid(),
            category: "",
            puzzle: "",
            finished: false
    }]);
    const [isLoading, setIsLoading] = useState(false);

    const backdropCallback = props?.backdropCallback;
    const setupCallback = props?.setupCallback;

    useEffect(() => {
        setBackdrop("var(--umw-col-red)");
    },[]);

    // Checks to see if the given puzzle string will fit on the board (including spaces to avoid word-breaks)
    // puzzleString - The puzzle to check
    function puzzleCanFit(puzzleString) {
        let willFit = true;

        if (puzzleString.length <= 60) {

            let words = puzzleString.split(" ");
            let currentRowIdx = 1;
            let currentArrIdx = 0;

            for (const word of words) {
                const wordLen = word.length;

                if (wordLen > 15) {
                    willFit = false;
                    break;
                }

                if (currentArrIdx + wordLen <= 15 * currentRowIdx) {
                    currentArrIdx += wordLen
                } else {
                    currentArrIdx = currentRowIdx * 15;
                    currentRowIdx += 1
                    currentArrIdx += wordLen;
                }

                currentArrIdx += 1;

                if (currentArrIdx > 59) {
                    willFit = false;
                    break;
                }
            }

        } else {
            willFit = false;
        }

        return willFit;
    }

    function updateVowelCost(e) {
        let val = Number(e.target.value);
        if (!isNaN(val) && val >= 0 && val <= 99999) {
            setVowelCost(val);
        }
    }

    function getBackdrops() {
        let backdrops = [];
        for (const solid of solids) {
            backdrops.push(<div className={"card" + ((activeBackdrop === solid) ? " active" : "")}
                                key={solid}
                                style={{backgroundColor: solid}}
                                onClick={setBackdrop.bind(this, solid)}></div>)
        }
        for (const [key, path] of Object.entries(thumbnails)) {
            backdrops.push(<div className={"card" + ((activeBackdrop === key) ? " active" : "")}
                                key={key}
                                style={{backgroundImage: `url(${path})`}}
                                onClick={setBackdrop.bind(this, key)}></div>)
        }
        return backdrops;
    }

    function setBackdrop(img) {
        setActiveBackdrop(img);
        if (backdropCallback) {
            backdropCallback(img);
        }
    }

    function updatePuzzleSet(puzzleID, updateType, e) {
        let puzzleUpdate = puzzleSet.slice();
        let puzzle = puzzleUpdate.find((x)=> x.id === puzzleID);
        if (puzzle) {

            if (updateType === "puzzle" && !puzzleCanFit(e.target.value)) {
                e.target.classList.add("invalid");
            } else {
                e.target.classList.remove("invalid");
            }

            puzzle[updateType] = e.target.value;
        }
        setPuzzleSet(puzzleUpdate);

    }

    function addNewPuzzleRow() {
        let puzzleUpdate = puzzleSet.slice();
        puzzleUpdate.push({
            id: uuid(),
            category: "",
            puzzle: "",
            finished: false
        });
        setPuzzleSet(puzzleUpdate);
    }

    function deletePuzzleRow(puzzleID) {
        let puzzleUpdate = puzzleSet.slice();
        puzzleUpdate = puzzleUpdate.filter((x)=> x.id !== puzzleID);
        setPuzzleSet(puzzleUpdate);
    }

    function getPuzzleRows() {
        let puzzleRows = [];
        for (const puzzle of puzzleSet) {
            puzzleRows.push(
                <form key={puzzle.id} className="puzzle-row centered-flex gap-1">
                    <input required={true} type="text" maxLength="20" minLength="1" placeholder="Category" onChange={updatePuzzleSet.bind(this, puzzle.id, "category")} value={puzzle.category}/>
                    <input required={true} type="text" maxLength="60" minLength="1" className="flex-1" placeholder="Puzzle" onChange={updatePuzzleSet.bind(this, puzzle.id, "puzzle")} value={puzzle.puzzle}/>
                    <button className="delete-btn" onClick={deletePuzzleRow.bind(this, puzzle.id)}>×</button>
                </form>
            )
        }
        return puzzleRows;
    }

    function verifyPuzzleSet() {

        if (!puzzleSet || puzzleSet.length === 0) {
            alert("You must add at least one puzzle.");
            return false;
        }

        if (document.querySelectorAll('#game-setup .invalid').length > 0) {
            alert("There are some invalid puzzles in this set. If it is highlighted red, that means the puzzle will not physically fit on the board. Please edit it until it is no longer highlighted red.");
            return false;
        }

        let forms = document.querySelectorAll('#game-setup form');
        for (let i = 0; i < forms.length; i++) {
            if (!forms[i].reportValidity()) {
                alert("You are missing some required fields. Check your input and try again.");
                return false;
            }
        }

        return true;
    }

    function _finalizePuzzleSet() {

        if (setupCallback && verifyPuzzleSet()) {
            setupCallback(puzzleSet.map(
                (x) => {
                    x.category = x.category.toUpperCase();
                    x.puzzle = x.puzzle.toUpperCase();
                    return x;
                }
            ), playerCount, vowelCost);
        }
    }

    function _savePuzzleSet() {
        if (verifyPuzzleSet()) {
            let saveName = prompt("Please enter a name for this save file:");
            if (saveName) {
                let puzzleSetSlice = puzzleSet.slice();
                puzzleSetSlice = puzzleSetSlice.map((x)=> {
                   return {...x, finished: false}
                });
                downloadTxt(saveName, JSON.stringify(puzzleSetSlice));
            }
        }
    }

    function downloadTxt(saveName, content) {
        if ('Blob' in window) {
            if (saveName) {
                saveName += ".txt";
                let textToWrite = content.replace(/\n/g, "\r\n");
                let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });

                if ('msSaveOrOpenBlob' in navigator) {
                    navigator.msSaveOrOpenBlob(textFileAsBlob, saveName);
                } else {
                    let downloadLink = document.createElement('a');
                    downloadLink.download = saveName;
                    downloadLink.innerHTML = 'Download File';

                    if ('webkitURL' in window) {
                        // Chrome allows the link to be clicked without actually adding it to the DOM.
                        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
                    } else {
                        // Firefox requires the link to be added to the DOM before it can be clicked.
                        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
                        downloadLink.click(function(event){
                            document.body.removeChild(event.target);
                        });

                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                    }
                    downloadLink.click();
                }
            }
        } else {
            alert('Your browser does not support the HTML5 Blob.');
        }
    }

    function startUpload(e) {
        document.querySelector('#loadPanel button').classList.add("disabled");
        document.querySelector('#load-puzzle-set-btn').classList.add("disabled");
        setIsLoading(true);
        loadPuzzleSet(e);
    }

    function loadPuzzleSet(e) {
        try {
            let files = e.target.files; // FileList object

            // use the 1st file from the list
            let f = files[0];

            let reader = new FileReader();

            // Closure to capture the file information.
            reader.onload = (function(theFile) {
                return function(e) {
                    let puzzleSet = JSON.parse(e.target.result);
                    setPuzzleSet(puzzleSet);
                    setIsLoading(false);
                    setPanelIdx(2);
                };
            })(f);

            // Read in the image file as a data URL.
            reader.readAsText(f);
        } catch (e) {
            setIsLoading(false);
            alert('An error occurred.');
            //btns.prop('disabled', false);
        }
    }


    let bkgPath = (activeBackdrop) ?
        ((bkgImages.hasOwnProperty(activeBackdrop)) ? bkgImages[activeBackdrop] :
            ((solids.includes(activeBackdrop) ? activeBackdrop : ""))) : "";

    const backFromCreate = useDebounce(()=> {
        if (window.confirm("You are sure you want to go back? Any unsaved lists will be deleted.")) {
            setPuzzleSet([{
                id: uuid(),
                category: "",
                puzzle: "",
                finished: false
            }]);
            setPanelIdx(1);
        }
    });

    const savePuzzleSet = useDebounce(_savePuzzleSet);
    const finalizePuzzleSet = useDebounce(_finalizePuzzleSet);

    return (
        <div id="game-setup" style={(solids.includes(activeBackdrop)) ? {backgroundColor: activeBackdrop} : {backgroundImage: `url(${bkgPath})`}}>
            {panelIdx === 0 && <div id="groupsPanel" className="panel">
                <header>Basic Settings:</header>
                <div className="centered-flex flex-col gap-1 flex-1">
                    <div className="settings-container-1">
                        <div>
                            <h1>How many players/groups?</h1>
                            <div id="player-count-entry" className="dec-inc-input">
                                <button className="dec-btn"
                                        onClick={setPlayerCount.bind(this, (playerCount > 1) ? playerCount - 1 : 1)}>−</button>
                                <div className="value">{playerCount}</div>
                                <button className="inc-btn"
                                        onClick={setPlayerCount.bind(this, (playerCount < 20) ? playerCount + 1 : 20)}>+</button>
                            </div>
                        </div>
                        <div>
                            <h1>How much does a vowel cost?</h1>
                            <div id="player-count-entry" className="dec-inc-input">
                                <button className="dec-btn"
                                        onClick={setVowelCost.bind(this, (vowelCost > 0) ? vowelCost - 1 : 0)}>−</button>
                                <input type="text" onChange={updateVowelCost} className="value" value={vowelCost}></input>
                                <button className="inc-btn"
                                        onClick={setVowelCost.bind(this, (vowelCost < 99999) ? vowelCost + 1 : 99999)}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="settings-container-2">
                        <h1 style={{margin:0}}>Choose a backdrop:</h1>
                        <div className="scrolling-wrapper">
                            {getBackdrops()}
                        </div>
                    </div>
                </div>
                <button style={{margin:"auto"}} onClick={setPanelIdx.bind(this, 1)}>Next</button>
            </div> }
            {panelIdx === 1 && <div id="createOrLoadPanel" className="panel">
                <header>Puzzle Set:</header>
                <div className="centered-flex flex-col gap-1 flex-1">
                    <h1 style={{textAlign:"center"}}>You can create a new puzzle set or load a saved one. Which would you like to do?</h1>
                    <div className="centered-flex flex-col flex-1 gap-1">
                        <button style={{marginBottom:"1rem"}} onClick={setPanelIdx.bind(this, 2)}>Create</button>
                        <button onClick={setPanelIdx.bind(this, 3)}>Load</button>
                    </div>
                </div>
            </div> }
            {panelIdx === 2 && <div id="createPanel" className="panel">
                <header>New Puzzle Set</header>
                <div className="flex flex-col gap-1 flex-1">
                    <h1>Enter your puzzles below:</h1>
                    <button id="add-puzzle-btn" onClick={addNewPuzzleRow}>+</button>
                    <div id="puzzle-set-holder">
                        {getPuzzleRows()}
                    </div>
                    <div className="nav-holder">
                        <button onClick={backFromCreate}>Back</button>
                        <button onClick={savePuzzleSet}>Download</button>
                        <button onClick={finalizePuzzleSet}>Play</button>
                    </div>
                </div>
            </div> }
            {panelIdx === 3 && <div id="loadPanel" className="panel">
                <header>Load Puzzle Set</header>
                {(isLoading) ? (
                    <div className="lds-default">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                ) : (<>
                    <div className="flex flex-col gap-1 flex-1">
                        <h1>Choose a file to upload:</h1>
                        <div className="centered-flex flex-col gap-1 flex-1">
                            <label className="custom-file-upload">Upload...
                                <input id="load-puzzle-set-btn" onChange={startUpload} type="file" name="files[]"
                                       style={{display: "none"}}/>
                            </label>
                            <button onClick={setPanelIdx.bind(this, 1)}>Back</button>
                        </div>
                    </div>
                    </>)}
            </div> }
        </div>
    );
}

export default GameSetup;