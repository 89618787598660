import React, {} from 'react';
import defaultBackdrop from './img/backdrop_1.png';

function PuzzleBoard(props) {

    const boardState = props?.boardState;
    const backdrop = props?.backdrop || defaultBackdrop;
    const guessedBlockCallback = props?.guessedCallback || (()=>{});
    const solved = props?.solved || false;
    const showNextBtn = props?.showNextBtn || false;
    const showFinishBtn = props?.showFinishBtn || false;
    const nextCallback = props?.nextCallback;
    const category = props?.puzzle?.category;

    function getBlocks() {

        let blocks = [];

        if (boardState) {
            for (let i = 0; i < boardState.length; i++) {
                let block = boardState[i];
                let className = `l${i+1}` +
                    ((block.used) ? " used" : "") +
                    ((block.guessed) ? " guessed" : "") +
                    ((block.show || solved) ? " show" : "");

                let blockEl = <div key={`l${i+1}`}
                                   className={className}
                                   onClick={(block.guessed) ? guessedBlockCallback.bind(null, i) : (()=>{})}>
                    {block.value}</div>;
                blocks.push(blockEl);

            }
        }
        return blocks;
    }

    let backroundStyle = (backdrop) ?
        ((backdrop.indexOf("--umw") !== -1) ? {backgroundColor: backdrop} : {backgroundImage: `url(${backdrop})`}) : {};

    return (
        <div id="puzzle-board" style={backroundStyle}>
            {(showNextBtn || showFinishBtn) && <div id="next-container"><button id="next-puzzle-btn" onClick={() => {
                if (nextCallback) {
                    document.querySelector('#next-puzzle-btn').classList.add('disabled');
                    nextCallback();
                }
            }}>{(showFinishBtn) ? "Finish" : "Next"}</button></div>}
            {category && !showNextBtn && !showFinishBtn && <div id="category-container">{category}</div>}
            <div className="puzzle-container">
                {getBlocks()}
            </div>
        </div>
    );
}

export default PuzzleBoard;