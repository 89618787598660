import { motion } from 'framer-motion';
import first from "./img/1.svg";
import second from "./img/2.svg";
import third from "./img/3.svg";

export default function WinnerCard(props) {

    const winners = props?.winners;
    const winner = props?.winner;
    const index = props?.index;

    const positions = {
        0: '1st',
        1: '2nd',
        2: '3rd'
    };

    const placeImgs = [
        first,
        second,
        third
    ];

    return (
        <motion.div
            custom={index}
            initial="hidden"
            animate="visible"
            variants={{
                visible: () => ({
                    opacity: 1,
                    y: 0,
                    transition: {
                        delay: (winner.place === 2) ? 1 : 1 + (winners.length - winner.place),
                        duration: 2,
                        ease: 'backInOut'
                    }
                }),
                hidden: { opacity: 0, y: -100 }
            }}
            key={winner.id}
            className=""
        >
            <div
                className="winner-card"
            >
                <img
                    src={placeImgs[winner.place]}
                    alt=""
                    className="winner-card-img"
                />

                <div className="winner-card-info">
                    <p className="text-gray-900 leading-none font-semibold">
                        {winner.name || 'No name'}
                    </p>
                    <p className="text-gray-600">{winner.score}</p>
                </div>
            </div>
        </motion.div>
    )
}
