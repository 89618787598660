import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as PlaySVG} from '../../static/img/play.svg';
import useDebounce from "../../useDebounce";

function Preloader(props) {


    const imageList = props?.images || [];
    const soundList = props?.audio;
    const continueCallback = useDebounce(props?.callback || (()=>{}));
    const [finishedLoading, setFinishedLoading] = useState(false);
    const total = useRef(imageList.length + soundList.length);
    const [completed, setCompleted] = useState(0);

    useEffect(()=> {
        startPreload(imageList)
    }, []);

    function startPreload(srcs) {
        let imgs = [];
        let remaining = srcs.length;

        if (remaining === 0) {
            preloadSounds(soundList);
            return;
        }

        for (let i = 0; i < srcs.length; i++) {
            let img = new Image();
            img.onload = function() {
                --remaining;
                setCompleted(imageList.length - remaining);
                if (remaining <= 0) {
                    preloadSounds(soundList);
                }
            };
            img.src = srcs[i];
            imgs.push(img);
        }
    }

    function preloadSounds(srcs) {
        let sounds = {};
        let remaining = srcs.length;

        if (remaining === 0) {
            setFinishedLoading(true);
            return;
        }

        for (let i = 0; i < srcs.length; i++) {
            let aud = new Audio();

            aud.onloadeddata = function () {
                --remaining;
                setCompleted(imageList.length + (soundList.length - remaining));
                if (remaining <= 0) {
                    setFinishedLoading(true);
                    window.UNMEIWA = {};
                    window.UNMEIWA.sounds = sounds;

                }
            };

            aud.src = srcs[i];
            aud.preload = "auto";

            sounds[srcs[i]] = aud;
        }
    }

    function finalize(e) {
        window.UNMEIWA.NoSleep = new window.NoSleep("Unmeiwa");
        window.UNMEIWA.NoSleep.enable();
        continueCallback();
    }

    return (
        <div id="preloader">
            {!finishedLoading && (
                <>
                    <div className="loading-info">{`Loading: ${completed}/${total.current}`} </div>
                    <div className="lds-default">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </>

            )}
            {finishedLoading && (
                <>
                    <div className="title">Click to play</div>
                    <button onClick={finalize}><PlaySVG height="100%"/></button>
                </>
            )}
        </div>
    );
}

export default Preloader;