import { motion } from 'framer-motion'
import first from './img/1.svg';
import second from './img/2.svg';
import third from './img/3.svg';

export default function PodiumStep(props) {

    const podium = props?.podium;
    const winner = props?.winner;
    const index = props?.index;

    const positions = {
        0: '1st',
        1: '2nd',
        2: '3rd'
    };

    const placeImgs = [
      first,
      second,
      third
    ];


    return (
        <div className={`podium-step step-${winner.place}`}>
            <motion.div
                custom={index}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: () => ({
                        opacity: 1,
                        transition: {
                            delay: (winner.place === 2) ? 1 : 1 + (podium.length - winner.place),
                            duration: 2
                        }
                    }),
                    hidden: { opacity: 0 }
                }}
                className="place-img"
            >
                <img
                    src={placeImgs[winner.rank - 1]}
                    alt=""
                />
            </motion.div>
            <motion.div
                custom={index}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: () => ({
                        height: 200 * ((podium.length - winner.place) / podium.length),
                        opacity: 2,
                        transition: {
                            delay: (winner.place === 2) ? 1 : 1 + (podium.length - winner.place),
                            duration: 2,
                            ease: 'backInOut'
                        }
                    }),
                    hidden: { opacity: 0, height: 0 }
                }}
                className="podium-bar"
                style={{
                    marginBottom: -1,
                    filter: `opacity(${
                        0.1 + (podium.length - winner.place) / podium.length
                    })`
                }}
            >
        <span>
          {winner.name}
        </span>
            </motion.div>
        </div>
    )
}