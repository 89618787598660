import {useRef, useState} from "react";
import PuzzleBoard from "../PuzzleBoard";
import selection from "../../static/audio/selection.mp3";

const { floor, sqrt } = Math;
const { now } = Date;

function RouletteWheel(props) {


    const puzzle = props?.puzzle;
    const boardState = props?.boardState;
    const backdrop = props?.backdrop;
    const continueCallback = props?.callback || startSpin;
    const group = props?.group;

    const audio = useRef();
    const spinBtn = useRef();
    const [result, setResult] = useState(null);

    let currentBlock = 0;
    let startTime;
    let gClass;

    const DURATION = 3500;
    const MIN_UPDATES = 50;
    const MAX_UPDATES = 301;

    if (group?.id) {
        let mod = (group.id % 4 === 0) ? 4 : group.id % 4;
        gClass = `g${mod}`;
    }


    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    let total_updates = getRandomInt(MIN_UPDATES, MAX_UPDATES);

    let elapsed = 0;
    let updates = 0;

    const easeOutCirc = (t, b, c, d) => {

        t /= d;
        t--;

        return c * sqrt(1 - t*t) + b;
    }

    function startSpin(e) {

        if (!audio.current) {
            audio.current = window.UNMEIWA.sounds[selection];
        }
        spinBtn?.current.classList.add('locked');
        resetSpin();
        startTime = now();
        changeBlock();
        playSound();
    }

    function resetSpin() {
        elapsed = 0;
        updates = 0;
        total_updates = getRandomInt(MIN_UPDATES, MAX_UPDATES);
        document.querySelector('#roulette-wheel > div.active')?.classList.remove('active');
    }

    function changeBlock() {
        elapsed = now() - startTime;

        elapsed = (elapsed > DURATION) ? DURATION : elapsed;

        const newUpdates = floor(easeOutCirc(elapsed, 0, total_updates, DURATION));

        if (newUpdates > updates) {
            updates = newUpdates;
            if (currentBlock !== 0) {
                document.querySelector(`.r${currentBlock}`).classList.remove('active');
            }

            //let newVal = getRandomInt(1, 25);
            //currentBlock = (currentBlock !== newVal) ? newVal : getRandomInt(1, 25);

            currentBlock += 1;
            if (currentBlock > 24) {
                currentBlock = 1;
            }
            document.querySelector(`.r${currentBlock}`).classList.toggle('active');
        }

        if (updates < total_updates) {
            requestAnimationFrame(changeBlock);
        }
    }

    function processResult() {
        let finalBlock = document.querySelector('#roulette-wheel > div.active');
        spinBtn?.current.classList.remove("locked");
        setResult(finalBlock.textContent)
    }

    function playSound()
    {
        audio.current.onended = processResult;
        audio.current.play().catch((e)=>{
            console.log(e);
        });
    }

    function followCallback() {
        if (continueCallback) {
            continueCallback(result);
        }
    }

    return (

        <div id="roulette-wheel" className={gClass}>
            <div className="r1">500</div>
            <div className="r2">900</div>
            <div className="r3">700</div>
            <div className="r4">300</div>
            <div className="r5">800</div>
            <div className="bankrupt r6">BANKRUPT</div>
            <div className="r7">400</div>
            <div className="r8">500</div>
            <div className="r9">600</div>
            <div className="r10">350</div>
            <div className="r11">500</div>
            <div className="r12">900</div>
            <div className="bankrupt r13">BANKRUPT</div>
            <div className="r14">650</div>
            <div className="free-vowel r15">FREE VOWEL</div>
            <div className="r16">700</div>
            <div className="lose-a-turn r17">LOSE A TURN</div>
            <div className="r18">800</div>
            <div className="r19">500</div>
            <div className="r20">450</div>
            <div className="r21">500</div>
            <div className="r22">300</div>
            <div className="bankrupt r23">BANKRUPT</div>
            <div className="r24">2000</div>
            <div className="puzzle">
                <PuzzleBoard backdrop={backdrop} boardState={boardState} puzzle={puzzle}/>
                <button id="spinBtn" className={gClass} ref={spinBtn} onClick={(result) ? followCallback : startSpin}>{(result) ? result : "SPIN"}</button>
            </div>
        </div>
    );
}

export default RouletteWheel;