import WinnerCard from './WinnerCard'

export default function WinnerList(props) {

    const winners = props?.winners;

    return (
        <div className="winners-list-holder">
            {winners.map((winner, index) => (
                <WinnerCard
                    key={winner.id}
                    winners={winners}
                    winner={winner}
                    index={index}
                />
            ))}
        </div>
    )
}
